import React, { useEffect, useRef } from 'react';
import '/Users/pathf/Godfather/website/src/App.css';


const WinampPlayer = ({ onClose }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/webamp@1.4.2/built/webamp.bundle.min.js';
    script.onload = () => {
      const Webamp = window.Webamp;
      const webamp = new Webamp({
      initialSkin: {
        url: 'https://archive.org/cors/winampskin_windows98/windows98.wsz'
      },
      availableSkins: [
        {
          url: "https://archive.org/cors/winampskin_White_Clouds/White_Clouds.wsz",
          name: "White Clouds",
        },
        {
          url: "https://archive.org/cors/winampskin_Subaru_Impreza_v1/Subaru_Impreza_v1.wsz",
          name: "Subaru Impreza",
        },
        {
          url: "https://archive.org/cors/winampskin_Windows_Standard/Windows_Standard.wsz",
          name: "Windows98",
        },
        {
          url: "https://archive.org/cors/winampskin_Winamp98_plus_IE5/Winamp98_plus_IE5.wsz",
          name: "Winamp 98",
        },
        {
          url: "https://archive.org/cors/winampskin_Rei_Ayanami_Akarui_Skin/Rei_Ayanami_Akarui_Skin.wsz",
          name: "Rei Ayanami Akarui",
        },
        {
          url: "https://archive.org/cors/winampskin_Hate_You/Hate_You.wsz",
          name: "Hate You",
        },
        {
          url: "https://archive.org/cors/winampskin_White_Clouds/White_Clouds.wsz",
          name: "White Clouds",
        },
        {
          url: "https://archive.org/cors/winampskin_windows98/windows98.wsz",
          name: "Initial Skin",
        },
      ],
      initialTracks: [
        {
          metaData: {
            artist: "gabemtnz",
            title: "GLITCH IN YOUR HEART",
          },
          url: "https://0rb5.github.io/SpiritTracks/GLITCH IN YOUR HEART.mp3",
          duration: 131,
        },
        {
          metaData: {
            artist: "2hollis",
            title: "God",
          },
          url: "https://0rb5.github.io/SpiritTracks/2hollis - god (official video).mp3",
          duration: 187,
        },
        {
          metaData: {
            artist: "Kankan",
            title: "Nun To Me 2",
          },
          url: "https://0rb5.github.io/SpiritTracks/Kankan - Nun To Me 2.mp3",
          duration: 5.322286,
        },
        {
          metaData: {
            artist: "Summrs",
            title: "Till We Meet Again",
          },
          url: "https://0rb5.github.io/SpiritTracks/Summrs - Til We Meet Again (Prod. Twin Tecs  RT  Goyxrd).mp3",
          duration: 123,
        },
        {
          metaData: {
            artist: "Autumn!",
            title: "COCAINE DIAMONDS!",
          },
          url: "https://0rb5.github.io/SpiritTracks/Autumn! - COCAINE DIAMONDS! [Midnight Club].mp3",
          duration: 155,
        },
        {
          metaData: {
            artist: "Yabujin",
            title: "Chalice Of Mind 守卫大门的鲸鱼",
          },
          url: "https://0rb5.github.io/SpiritTracks/Yabujin - Chalice Of Mind 守卫大门的鲸鱼(Music Video).mp3",
          duration: 137,
        },
        {
          metaData: {
            artist: "Nettspend",
            title: "We Not Like You",
          },
          url: "https://0rb5.github.io/SpiritTracks/Nettspend - We Not Like You [Dir. by Tycho].mp3",
          duration: 67,
        },
        {
          metaData: {
            artist: "Yung Fazo",
            title: "Fk Em",
          },
          url: "https://0rb5.github.io/SpiritTracks/Yung Fazo - Fk Em (Official Music Video).mp3",
          duration: 115,
        },
        {
          metaData: {
            artist: "Ecco2K",
            title: "Calcium (Show Me Love Mix)",
          },
          url: "https://0rb5.github.io/SpiritTracks/Ecco2K - Calcium (Show Me Love Mix) (Prod. Maple).mp3",
          duration: 305,
        },
        {
          metaData: {
            artist: "Yeat",
            title: "Tru to U",
          },
          url: "https://0rb5.github.io/SpiritTracks/Tru to U.mp4",
          duration: 236,
        },
        {
          metaData: {
            artist: "Summrs",
            title: "Baby Blue Gwag",
          },
          url: "https://0rb5.github.io/SpiritTracks/Summrs - Baby Blue Gwag.mp3",
          duration: 160,
        },
        {
          metaData: {
            artist: "Autumn!",
            title: "SURVIVE THE FALL!",
          },
          url: "https://0rb5.github.io/SpiritTracks/Autumn! - SURVIVE THE FALL! [Midnight Club].mp3",
          duration: 170,
        },
        {
          metaData: {
            artist: "Starcatcher",
            title: "Kefka2006.Dekishi2.7",
          },
          url: "https://0rb5.github.io/SpiritTracks/Kefka2006.Dekishi2.7.mp4",
          duration: 77,
        },
        {
          metaData: {
            artist: "Lil Clearpill",
            title: "BLiNDED (timetravel. Remix)",
          },
          url: "https://0rb5.github.io/SpiritTracks/BLiNDED (timetravel. Remix).mp4",
          duration: 231,
        },
        {
          metaData: {
            artist: "timetravel",
            title: "I Wanna Kill Myself",
          },
          url: "https://0rb5.github.io/SpiritTracks/I Wanna Kill Myself.mp4",
          duration: 186,
        },
      ],
    });

    webamp.renderWhenReady(containerRef.current);
  };

  document.body.appendChild(script);

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="winamp-container">
      <div ref={containerRef} id="app"></div>
      
    </div>
  );
};

export default WinampPlayer;
