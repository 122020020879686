// src/components/Desktop.js
import React, { useState, useEffect, useMemo } from 'react';
import Notepad from './Notepad';
import '../App.css';
import { EventEmitter } from './EventEmitter';
import { Modal } from '@react95/core';
import { Awfxcg321305, Progman22, Explorer100, Winmine1, Mspaint, FlyingThroughSpace100, FolderSettings, Issue, Explore, InfoBubble, Mail3, Mplayer13, Progman15, Explorer105 } from '@react95/icons';
import DesktopIcon from './DesktopIcon';
import WinampPlayer from './WinampPlayer';
import WinampIcon from './WinampIcon.png';



function WebsiteDesktop() {
    const isMobile = window.innerWidth < 850;
    const [openEditors, setOpenEditors] = useState([]);
    const [showInfo, setShowInfo] = useState(true);
    const [showGodfatherLinks] = useState(true);
    const [showWinamp, setShowWinamp] = useState(false);

    const handleWinampClick = () => {
        setShowWinamp(prevState => !prevState);
    };

    const editors = useMemo(() => [
        { id: 'about', name: 'About', ico: <Issue /> },
        { id: 'programs', name: 'Links', ico: <Explorer105 /> },
        { id: 'docs', name: 'Explorer', ico: <Explore /> },
        { id: 'contact', name: 'Contact', ico: <Mail3 /> },
        { id: 'etf', name: 'Paint', ico: <Mspaint /> },
        { id: 'video', name: 'Video Player', ico: <Mplayer13 /> },
        { id: 'randomness', name: 'How To Buy $GODFATHER', ico: <Awfxcg321305 /> },
        { id: 'timelock', name: 'Solana Tools', ico: <FolderSettings /> },
        { id: 'dtxs', name: '$GODFATHER Charts', ico: <Progman15 /> },
        { id: 'winamp', name: 'Winamp', ico: <WinampIcon /> },
        { id: 'minesweeper', name: 'Minesweeper', ico: <Winmine1 /> },
        { id: 'win95', name: 'Win95coin', ico: <Progman22 /> },
       
    ], []);

    useEffect(() => {
        editors.forEach(editor => {
            if (editor.id !== 'winamp') {
                EventEmitter.subscribe(editor.id, () => {
                    openNotepad(editor);
                });
            }
        });

        EventEmitter.subscribe('winamp', handleWinampClick);

        return () => {
            editors.forEach(editor => {
                EventEmitter.subscribe(editor.id);
            });
            EventEmitter.subscribe('winamp');
        };
    }, [editors]);

    const getNextX = (n) => `${25 + 5 * n}%`;
    const getNextY = (n) => `${15 + 5 * n}%`;

    const closeNotepad = (id) => {
        setOpenEditors(openEditors.filter(editor => editor.id !== id));
    };

    const openNotepad = (item) => {
        setOpenEditors(prevEditors => {
            if (!prevEditors.some(editor => editor.id === item.id)) {
                return [...prevEditors, item];
            }
            return prevEditors;
        });
    };

    const getCenteredLeft = (width) => `calc(50% - ${width / 2}px)`;
    const getCenteredTop = (height) => `calc(50% - ${height / 2}px)`;

    return (
        <>
            {showGodfatherLinks && (
                <Modal
                    icon={<FlyingThroughSpace100 />}
                    title={`$GODFATHER Links`}
                    style={{
                        left: isMobile ? '5%' : '10%',
                        top: isMobile ? '3%' : '5%',
                        width: isMobile ? '90%' : 450,
                    }}
                >
                    <div className='centered'>
                        <span className='title'>$GODFATHER</span>
                        <div className='links'>
                            <a target='_blank' rel="noreferrer" href='https://x.com/Godfather_onsol'>Twitter</a> |
                            <a target='_blank' rel="noreferrer" href='https://dexscreener.com/solana/b7epkjnws4wekejryckw8nlnhrbzjtwpmtf4paayuc5j'>DexScreener</a> |
                            <a target='_blank' rel="noreferrer" href='https://t.me/THESOLGODFATHER'>Telegram</a>
                        </div>
                    </div>
                </Modal>
            )}
            {showInfo && (
                <Modal
                    icon={<InfoBubble />}
                    title={`info`}
                    closeModal={() => setShowInfo(false)}
                    style={{
                        left: isMobile ? '30%' : '66%',
                        top: isMobile ? '5%' : '1%',
                        width: isMobile ? '60%' : 430,
                        
                    }}
                >
                    <div className='centered'>
                        <span>This website is best viewed on a laptop or PC.</span>
                    </div>
                </Modal>
            )}
            {openEditors.map((editor, idx) => (
                <Notepad
                    key={editor.id}
                    ico={editor.ico}
                    closeNotepad={() => closeNotepad(editor.id)}
                    selectedItem={editor}
                    isMobile={isMobile}
                    left={editor.id === 'docs' ? getCenteredLeft(900) : getNextX(idx)}
                    top={editor.id === 'docs' ? getCenteredTop(620) : getNextY(idx)}
                    width={editor.id === 'docs' ? 900 : undefined}
                    height={editor.id === 'docs' ? 590 : undefined}
                />
                    
            ))}
            
            <div className='init-icos'>
                <DesktopIcon ico={<Explorer100 style={{ width: '48px', height: '48px' }} />} text={<div className="icon-text my-computer-text">My Computer</div>} eventType={'terrabit'} />
                <DesktopIcon ico={<img src={WinampIcon} alt="" style={{ width: '48px', height: '48px' }} />} text="Winamp" onClick={handleWinampClick} />
                <DesktopIcon ico={<Explore variant="32x32_4" style={{ width: '48px', height: '48px'  }} />} text="Explorer" eventType={'docs'} />
                <DesktopIcon ico={<Mspaint variant="32x32_4" style={{ width: '48px', height: '48px'  }} />} text="Paint" eventType={'etf'} />
                <DesktopIcon ico={<Explorer105 variant="32x32_4" style={{ width: '48px', height: '48px' }} />} text="Links" eventType={'programs'} />
                <DesktopIcon ico={<Winmine1 variant="32x32_4" style={{ width: '48px', height: '48px'  }} />} text="Minesweeper" eventType={'minesweeper'} />
                <DesktopIcon ico={<Progman22 variant="32x32_4" style={{ width: '48px', height: '48px'  }} />} text="Win95coin" eventType={'win95'} />
            </div>
            {showWinamp && (
                <WinampPlayer
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000, // Ensure it is above other elements
                    }}
                    onClose={handleWinampClick}
                />
            )}
        </>
    );
}

export default WebsiteDesktop;
