import React from 'react';

function Paint() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: '0',
      }}
    >
    
      <iframe
        src="https://jspaint.app"
        frameBorder="0"
        title="paint"
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgb(192,192,192)',
          padding: '0',
        }}
      />
      {/* Temporarily remove the overlay div */}
      {/* {!isFocus && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        />
      )} */}
    </div>
  );
}

export default Paint;
