import { Frame } from '@react95/core'
import React from 'react'






function Randomness() {

    
    

    

    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={20}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                }}   
            >
                <h2>How to Buy $GODFATHER</h2>
                <h4>Wallet Setup & Purchasing Solana</h4>
                <p>
                    First things first, you need to Create a Crypto wallet that accepts solana. There's a bunch but, the best one to use is <a target="_blank" rel="noreferrer"href="https://phantom.app/">Phantom Wallet</a> they also allow you to buy crypto and pay in different ways.
                    <br/>&nbsp; &nbsp;You need to buy some solana, using the money you have. There's plenty of <a target="_blank" rel="noreferrer"href="https://coinmarketcap.com/rankings/exchanges/">Crypto Exchanges</a> they also allow you to buy crypto and pay in different ways.
                    <br/>&nbsp; &nbsp;Remember to never give away your private keys/seed phrase to your wallet, if someone gets ahold of those they have full access to your wallet and funds. Once you wallet is setup and you used your debit card to buy some solana and it's in your wallet, you're ready to go buy some $GODFATHER token.
                    

                    
                </p>
                <h4>Choosing your Trading Platform</h4>
                <p>
                    When it comes to trading solana tokens like Shit Coins & Meme Coins, they're not listed on the regular Crypto Exchanges like the ones I shared in previous section.
                    What you have to do is use these trading/swaping platforms, the main two are <a target="_blank" rel="noreferrer"href="https://raydium.io/swap/">Raydium</a> and <a target="_blank" rel="noreferrer"href="https://jup.ag/">Jupiter</a>.
                    <br/>&nbsp; &nbsp; There's also two good applications that exist on the Telegram Chat App, they're <a target="_blank" rel="noreferrer"href="https://www.bonkbot.io/">Bonkbot</a> and <a target="_blank" rel="noreferrer"href="https://x.com/trojanonsolana?lang=en">Trojan</a>.
                    <br/>&nbsp; &nbsp; Both of them make trading a lot more easier and imformative. After you decide on a platform you're ready to start buying some tokens!
                </p>
                <h4>Buying $GODFATHER</h4>    
                <p>
                    Now, that you have some solana and know what technology to use, you're ready to buy some $GODFATHER.
                    For this token, we will be launching $GODFATHER on <a target="_blank" rel="noreferrer"href="https://pump.fun/">Pump.fun</a> it allows you to buy a token at the very beginning of it's creation.
                    <br/>&nbsp; &nbsp; Go to the site connect your wallet from your browser or phone, and you will see a "Buy/Sell" buttons and you put how much you wanna buy in Solana.
                    <br/>&nbsp; &nbsp; Once $GODFATHER hit's a certain Market Cap on pump.fun it will then be transfered to raydium so that it may now be traded openly and not just on pump.fun.
                    
                </p>
                <h4>Looking at the Data</h4>
                <p>
                 Whether you choose to buy $GODFATHER during pump.fun or wait until the Raydium curve is up to you but, now you know how. Now, we use a bunch of Trading data sites to keep track of the prices of these tokens, the main two are <a target="_blank" rel="noreferrer"href="https://dextools.io">Dextools</a> and <a target="_blank" rel="noreferrer"href="https://dexscreener.com">DexScreener</a>.
                 <br/>&nbsp; &nbsp; On these platforms you will see live time action of tokens and their price movement, it goes eitherr up or down, you can see people's buys/sells and they also have a lot of good trading tools too you can use for your benefit.
                 <br/>&nbsp; &nbsp; If you're serious about learning how to trade I suggest you look into trading indicators and how they work. These sites will also give you the data that you need in order to decide if you're holding and buyinng more or if you're selling it all.
                 <br/>&nbsp; &nbsp; Whether you decide to buy $GODFATHER or not I hope you learned something here and checked out all the links I provided on this page.
                </p>
                
            </Frame>
        </div>
    )
}

export default Randomness