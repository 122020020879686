import React from 'react';
import { Frame, Video } from '@react95/core'; // Adjust the import if Video is from a different package
import { EXPLORER_VIDEO } from '/Users/pathf/Godfather/website/src/constants'; // Adjust the path to where EXPLORER_VIDEO is defined


function VideoPlayer() { 
    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={0}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                    textAlign: 'center'
                }}
            >
                <Video w="auto" src={EXPLORER_VIDEO} name="$GODFATHER The Movie" />
            </Frame>
        </div>
    );
}

export default VideoPlayer;
