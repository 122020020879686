import React from 'react';
import RawHtmlEmbed from '../RawHtmlEmbed'; // Adjust the import path as necessary

function Docs() {
    return (
        <div>
            <RawHtmlEmbed />
        </div>
    );
}

export default Docs;  
 