import { Frame } from '@react95/core'
import React from 'react'
import { Regedit102, Progman36, Winfile4, Progman1, Syncui128, Msrating106 } from '@react95/icons'


function Timelock() { 

    

    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={10}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                }}
            >
                <h2>Solana Tools</h2>
                <div className='contact-container'>
                    <div className='contact'>
                        <a target="_blank" rel="noreferrer"href="https://phantom.app/">   
                                <Progman36 variant="32x32_4" style={{ width: '7vw', height: '7vh' }} />
                                Phantom
                        </a>   
                        

                    </div>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://raydium.io/swap/">
                    <Syncui128 variant="32x32_4" style={{ width: '7vw', height: '7vh' }} />
                            Raydium
                        </a>
                    </div>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://jup.ag/">
                    <Msrating106 variant="32x32_4" style={{ width: '7vw', height: '7vh' }}/>
                            Jupiter
                        </a>
                    </div>
                    <br/><br/><br/><br/><br/><br/>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://www.bonkbot.io/">   
                    <Winfile4 variant="32x32_4" style={{ width: '7vw', height: '7vh' }} />
                           Bonkbot
                    </a>       
                    </div>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://x.com/trojanonsolana?lang=en">
                    <Progman1 variant="32x32_4" style={{ width: '7vw', height: '7vh' }}/>
                            Trojan
                        </a>
                    </div>
                    <div className='contact'>
                        <a target='_blank' rel="noreferrer" href='https://photon-sol.tinyastro.io/'>
                        <Regedit102 variant="32x32_4" style={{ width: '7vw', height: '7vh' }}/>
                            Photon-sol
                        </a>
                    </div>
                </div>
            </Frame>
        </div>
    )
}

export default Timelock