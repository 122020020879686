import React from 'react'
import About from './About'
import Programs from './Programs'
import EtfInfo from './Etf'
import Docs from './Documents'
import Contact from './Contact'
import Video from './VideoPlayer'
import Randomness from './Randomness'
import Timelock from './Timelock'
import DelayedTxs from './DelayedTxs'
import Minesweeper from './Minesweeper'
import Win95coin from './Win95coin'





function ContentFactory({ id, isMobile }) {

    switch (id) {
        case 'about':
            return <About />
        case 'contact':
            return <Contact />
        case 'programs':
            return <Programs />
        case 'docs':
            return <Docs />
        case 'etf':
            return <EtfInfo />
        case 'video':
            return <Video />
        case 'randomness':
            return <Randomness />
        case 'timelock':
            return <Timelock />
        case 'dtxs':
            return <DelayedTxs />
        case 'minesweeper':
            return <Minesweeper />
        case 'win95':
            return <Win95coin />
        default:
            return (<div></div>);
    }
}

export default ContentFactory