import { Frame } from '@react95/core'
import React from 'react'
import { Progman20, Sysmon1000, Drvspace7 } from '@react95/icons'




function DelayedTxs() {


    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={20}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                }}
            >
                <h2>$GODFATHER Charts</h2>
                <div className='contact-container'>
                    <div className='contact'>
                        <a target="_blank" rel="noreferrer"href="https://www.dextools.io/app/en/solana/pair-explorer/B7EpkjNWs4wekEJRyckW8nLNhRbZjtWPMtf4pAayuC5j?t=1719277707731">   
                        <Progman20 variant="32x32_4" style={{ width: '7vw', height: '7vh' }} />
                                Dextools
                        </a>   
                        

                    </div>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://dexscreener.com/solana/b7epkjnws4wekejryckw8nlnhrbzjtwpmtf4paayuc5j">
                    <Progman20 variant="32x32_1" style={{ width: '7vw', height: '7vh' }} />
                            Dexscreener
                        </a>
                    </div>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://birdeye.so/token/6qnK1GjMmdbaxKVMhqFmwbe98grW8CJXggyaeMwQpump?chain=solana">
                    <Sysmon1000 variant="32x32_4" style={{ width: '7vw', height: '7vh' }}/>
                            Birdeye
                        </a>
                    </div>
                    <br/><br/><br/><br/><br/><br/>
                    <div className='contact'>
                    <a target="_blank" rel="noreferrer"href="https://www.pump.fun/6qnK1GjMmdbaxKVMhqFmwbe98grW8CJXggyaeMwQpump">   
                    <Drvspace7 variant="32x32_4" style={{ width: '7vw', height: '7vh' }} />
                           Pump.fun
                    </a>       
                    </div>
                </div>
            </Frame>
        </div>
    )
}

export default DelayedTxs