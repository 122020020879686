import React from 'react';

function RawHtmlEmbed() {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: `
                    <style>
                        #dexscreener-embed { position: relative; width: 100%; padding-bottom: 100%; }
                        @media(min-width: 1400px) { #dexscreener-embed { padding-bottom: 65%; } }
                        #dexscreener-embed iframe { position: absolute; width: 100%; height: 60%; top: 0; left: 0; border: 0; }
                        @media(max-width: 700px) { #dexscreener-embed iframe { position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: 0; } }
                    </style>
                    <div id="dexscreener-embed">
                        <iframe src="https://www.google.com/webhp?igu=1"></iframe>
                    </div>
                `,
            }}
        />
    );
}

export default RawHtmlEmbed;
