import React from 'react';
import Win95coinEmbed from '../Win95coinEmbed';

function Win95coin() {
    return (
        <div>
            <Win95coinEmbed />
        </div>
    );
}

export default Win95coin; 