import { Frame } from '@react95/core'
import React from 'react'

function About() {
    return (
        <div>
            <Frame
                bg="white"
                boxShadow="in"
                height="100%"
                padding={20}
                style={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                }}
            >
                <h2>Bill Gates is...<img src="gates.png" alt="img" style={{ width: '13vw', marginLeft: '5vw', marginBottom: '-4.5vw' }} /></h2>
                <h1>$GODFATHER</h1>
                
                    <h2>The Man, The Myth, The Legend</h2>
                    
                    
                

                
                    
                    &nbsp; &nbsp; Bill Gates, the OG of the tech world, is the undisputed godfather of innovation. Without him, the tech scene wouldn't be the same—heck, it might not even exist!<br/> 
                    <br/>&nbsp; &nbsp; He's the big boss, the main character in the story of modern computing. Think about it: no Gates, no Microsoft domination. And without Microsoft, where would we be today? 
                    His moves weren't just about making stacks of cash; they were about shaping the future.<br/><br/>&nbsp; &nbsp; &nbsp; Now, he's not just chilling on his fortune. 
                    Nah, he's out there, throwing his weight around, proving he's not just a tech tycoon but a legit global player. 
                    <br/><br/>&nbsp; &nbsp; So, when you're talking about the ultimate crypto token, the one that's got the real street cred, you're talking about $GODFATHER. 
                    It's the Solana token that's carrying on the legacy of the man who made the digital age what it is today. 
                    <br/><br/>
                    <h2>BUY $GODFATHER NOW ON <a target='_blank' rel="noreferrer" href='https://www.pump.fun/6qnK1GjMmdbaxKVMhqFmwbe98grW8CJXggyaeMwQpump'>PUMP.FUN!!!</a></h2>
                    
                
            </Frame>
        </div>
    )
}

export default About