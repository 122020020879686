import React from 'react';
import MinesweeperEmbed from '../MinesweeperEmbed'; // Adjust the import path as necessary

function Minesweeper() {
    return (
        <div>
            <MinesweeperEmbed />
        </div>
    );
}

export default Minesweeper; 